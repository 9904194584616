@import "~antd/dist/antd.less";
@import "./variables.less";

.App {
	background: @warning-color;
}

.site-logo {
	height: 64px;
	line-height: 64px;
	text-align: center;
}

.link {
	color: @link-color;
	cursor: pointer;
}

.ant-layout {
	min-height: 100vh;
}

.align-center {
	text-align: center;
}

.top-margin {
	margin-top: 1rem;
}

.form-login-container {
	padding: 16px 32px;
	background-color: @greyish-white;
	border-radius: 4px;
}

#components-layout-demo-custom-trigger .trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 24px;
	cursor: pointer;
	transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
	color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
	height: 32px;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px;
}

.loader {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.site-layout .site-layout-background {
	background: #fcfcfc;
	position: relative;
}

.logo-container {
	margin: 10px auto auto;
	padding: 32px;
	border-radius: 50%;
	background-color: #414042;
	height: 90px;
	width: 90px;
}

.ant-layout-header {
	height: 64px;
	padding: 0 18px;
	background: rgb(170, 170, 170);
	line-height: 64px;
}

.site-header {
	display: flex;
	justify-content: space-between;
}

.call_icon {
	margin-left: 8px;
	font-size: 20px;
}

.ant-layout-sider {
	position: relative;
	min-width: 0;
	background: white;
	transition: all 0.2s;
}

.sidebar-container {
	position: relative;

	.sidebar-trigger {
		background-color: @primary-color;
		color: @greyish-white;
		line-height: 40px;
		position: absolute;
		right: 0;
		top: 0;
		transform: translateX(100%);
	}
}

.synthetic-id-icon-container {
	color: inherit;
	font-size: 1rem;

	.synthetic-id-icon {
		transition: transform 200ms ease-in;
	}

	.synthetic-id-icon:hover {
		transform: scale(1.25) translate(2px, -2px);
	}
}

// override
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item {
	margin-top: 0;
}

.scrollable {
	height: calc(100vh - 160px);
	overflow-y: scroll;

	.ant-list-header {
		background-color: @greyish-white;
		position: sticky;
		top: 0;
		z-index: 999;
	}
}

.case-reminder {
	height: 300px;
}

.lead-details {
	.lead-info {
		border: 1px solid @greyish-white;
		border-radius: 2px;
		padding: 16px 24px;
	}

	.lead-details-tabs {
		border-top: 1px solid @light-grey;
		border-right: 1px solid @light-grey;
		border-radius: 2px;
		padding: 16px 24px;

		.geturl-container {
			position: sticky;
			top: 0;
			padding: 4px 1rem;
			z-index: 1;
			background-color: #f1f1f1;
		}
	}

	.lead-image {
		.ant-image-img {
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	.recent-comment {
		height: 324px;
		overflow-y: scroll;
		width: 100%;

		.ant-list-header {
			background-color: #fcfcfc;
			position: sticky;
			top: 0;
			z-index: 1;
		}

		.delete-btn {
			width: 32px;
		}
	}

	.comment-section {
		.ant-row:nth-child(1) {
			flex: 10;
			padding: 16px 0;
			margin: 0 8px 0 0;
		}

		.ant-row:nth-child(2) {
			flex: auto;
			padding: 16px 0;
			margin: 0;
		}

		.ant-row:nth-child(3) {
			flex: auto;
			padding: 16px 0;
			margin: 0 0 0 8px;
		}
	}
}

.write-prescription {
	background-color: @primary-color;
	cursor: pointer;
	position: fixed;
	bottom: 1rem;
	right: 2rem;
	z-index: 99;
}
.order-data-card-body {
	width: 19rem;
	font-weight: bold;
	font-size: 40px;
}

.order_link_form {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.order-filter > .ant-form-item {
	margin-right: 0.25rem;
}

.image {
	.ant-image-img {
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}

.text-success {
	color: @success-color;
}
.text-success:hover,
.text-success:active,
.text-success:focus {
	color: hsl(100, 77%, 34%);
}
.text-danger {
	color: @error-color;
}
.text-danger:hover,
.text-danger:active,
.text-danger:focus {
	color: hsl(357, 91%, 45%);
}

.bg-transparent {
	background-color: transparent;
}
.bg-success {
	background-color: @success-color;
}
.bg-success-50 {
	background-color: hsl(100, 77%, 97%);
	border-color: hsl(100, 77%, 97%);
}
.bg-success-100 {
	background-color: hsl(100, 77%, 92%);
	border-color: hsl(100, 77%, 92%);
}
.bg-danger {
	background-color: @error-color;
}
.bg-danger-50 {
	background-color: hsl(357, 91%, 97%);
	border-color: hsl(357, 91%, 97%);
}

.block {
	display: block;
}

.grid {
	display: grid;
}
.admin__sales__dashboard-grid {
	grid-template-columns: repeat(5, minmax(15rem, 1fr));
}
.admin__post_sales__dashboard-grid {
	grid-template-columns: repeat(4, minmax(15rem, 1fr));
}
.ticket__attchments {
	grid-template-columns: repeat(3, minmax(96px, 1fr));
}
.grid-cols-1 {
	grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
	grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
	grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
	grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-6 {
	grid-template-columns: repeat(6, minmax(0, 1fr));
}

.flex {
	display: flex;
}
.flex-col {
	flex-direction: column;
}
.flex-1 {
	flex: 1 1 0%;
}

.items-start {
	align-items: flex-start;
}
.items-center {
	align-items: center;
}
.items-baseline {
	align-items: baseline;
}

.gap-1 {
	gap: 0.25rem;
}
.gap-2 {
	gap: 0.5rem;
}
.gap-3 {
	gap: 0.75rem;
}
.gap-4 {
	gap: 1rem;
}

.overflow-y-auto {
	overflow-y: auto;
}

.h-3 {
	height: 0.75rem;
}
.h-full {
	height: 100%;
}
.h-max {
	height: max-content;
}

.w-3 {
	width: 0.75rem;
}
.w-full {
	width: 100%;
}
.w-max {
	width: max-content;
}

.p-2 {
	padding: 0.5rem;
}
.pr-2 {
	padding-right: 0.5rem;
}
.px-2 {
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.m-4 {
	margin: 1rem;
}
.mr-2 {
	margin-right: 0.5rem;
}
.mr-4 {
	margin-right: 1rem;
}
.ml-1 {
	margin-left: 0.25rem;
}
.ml-2 {
	margin-left: 0.5rem;
}
.ml-4 {
	margin-left: 1rem;
}
.mt-px {
	margin-top: 1px;
}
.mt-2 {
	margin-top: 0.5rem;
}
.mt-4 {
	margin-top: 1rem;
}
.mt-16 {
	margin-top: 4rem;
}

.mb-0 {
	margin-bottom: 0 !important;
}
.mb-2 {
	margin-bottom: 0.5rem;
}
.mb-4 {
	margin-bottom: 1rem;
}
.mb-8 {
	margin-bottom: 2rem;
}

.justify-center {
	justify-content: center;
}
.justify-between {
	justify-content: space-between;
}
.justify-end {
	justify-content: flex-end;
}
.justify-start {
	justify-content: flex-start;
}

.justify-items-end {
	justify-items: end;
}
.justify-items-center {
	justify-items: center;
}

.capitalize {
	text-transform: capitalize;
}
.uppercase {
	text-transform: uppercase;
}

.text-center {
	text-align: center;
}

.pointer {
	cursor: pointer;
}

.whitespace-nowrap {
	white-space: nowrap;
}

.rc-virtual-list-scrollbar {
	display: block !important;
}
